import Vue from 'vue'
import App from './App.vue'
import router from './router'
import LoadScript from "/src/assets/js/vue-plugin-load-script/index";
import * as Sentry from "@sentry/vue";
import {BrowserTracing} from "@sentry/tracing";

Vue.config.productionTip = false

Vue.use(LoadScript);

try {
    const dsn = `${location.protocol}//${process.env.SENTRY_DSN}@${location.host}/sen/try/43`
    Sentry.init({
        Vue,
        dsn,
        integrations: [
            new BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router)
            }),
        ],
        environment: process.env.SENTRY_ENV,
        tracesSampleRate: 0.1,
    });
} catch (e) {
    console.error('Error sentry init', e)
}

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
