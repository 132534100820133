<template>
 <div></div>
</template>

<script>
export default {
  link: `https://connect.ok.ru/oauth/authorize?client_id=${process.env.OK_APPLICATION_ID}&scope=GET_EMAIL&response_type=token&redirect_uri=${location.origin}/auth-social/ok-after-redirect`,

  mounted() {
    this.$emit('is-loading', true)
    localStorage.setItem('redirect_uri', this.$route.query.redirect_uri)
    window.location.href = this.$options.link
  }
}
</script>