<template>
 <div></div>
</template>

<script>
/* eslint-disable */
export default {
  link: `https://www.facebook.com/${process.env.FACEBOOK_API_VERSION}/dialog/oauth?client_id=${process.env.FACEBOOK_CLIENT_ID}&redirect_uri=${location.origin}/auth-social/facebook-after-redirect&response_type=token`,

  mounted() {
    this.$emit('is-loading', true)
    localStorage.setItem('redirect_uri', this.$route.query.redirect_uri)
    window.location.href = this.$options.link
  }
}
</script>