<template>
 <div></div>
</template>

<script>
export default {
  link: `https://oauth.yandex.ru/authorize?response_type=token&client_id=${process.env.YANDEX_CLIENT_ID}&redirect_uri=${location.origin}/auth-social/yandex-after-redirect/`,

  mounted() {
    this.$emit('is-loading', true)
    localStorage.setItem('redirect_uri', this.$route.query.redirect_uri)
    window.location.href = this.$options.link
  }
}
</script>