<template>
  <div class="captcha-v2-wrapper">
    <div class="captcha-v2-modal">
      <div class="captcha-v2-modal__header">
        <div class="captcha-v2-modal__title"></div>
        <div class="custom-modal__close-btn" @click="onClose">
          <img :src="require('/src/assets/img/icon/close.svg')" alt=""/>
        </div>
      </div>

      <div id="recaptchav2" ref="v2" class="captcha-v2-modal__render"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    callback: {
      type: Function,
      require: true,
      default: () => {
      },
    },
  },
  emits: ['on-close'],
  async mounted() {
    await this.$loadScript(`https://www.google.com/recaptcha/api.js?render=${process.env.RECAPTCHA_V2}`)
    this.initReCaptcha()
  },
  methods: {
    initReCaptcha() {
      setTimeout(function () {
        // eslint-disable-next-line no-undef
        if (typeof grecaptcha === 'undefined' || typeof grecaptcha.render === 'undefined') {
          this.initReCaptcha();
        } else {
          try {
            // eslint-disable-next-line no-undef
            grecaptcha.render(document.getElementById('recaptchav2'), {
              sitekey: process.env.RECAPTCHA_V2,
              theme: 'dark',
              callback: (e) => {
                this.callback(e)
                this.onClose()
              },
            });
          } catch (e) {
            console.error('Render v2 error', e)
          }
        }
      }.bind(this), 100);
    },
    onClose() {
      this.$emit('on-close')
    },
  },
}
</script>

<style lang="scss">
.captcha-v2-wrapper {
  position: fixed;
  background: #03053488;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;

  .custom-modal__close-btn {
    width: 24px;
    height: 24px;
    cursor: pointer;
    margin-left: auto;
  }

  .captcha-v2-modal {
    background: #131f60;
    position: relative;
    width: 450px;
    border-radius: 15px;
    margin-bottom: 100px;
    padding-bottom: 24px;

    &__header {
      background: #242f6b;
      padding: 24px;
      border-radius: 15px 15px 0 0;
    }

    &__title {
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
    }

    &__render {
      margin-top: 24px;
      display: flex;
      justify-content: center;
    }
  }
}

@media screen and (max-width: 767px) {
  .captcha-v2-wrapper {
    .captcha-v2-modal {
      background: #131f60;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      border-radius: 15px 15px 0 0;
      margin-bottom: 0;
      padding-bottom: 16px;

      &__header {
        background: #242f6b;
        padding: 16px;
        border-radius: 15px 15px 0 0;
      }

      &__title {
        font-size: 18px;
        font-weight: 800;
      }

      &__render {
        margin-top: 24px;
      }
    }
  }
}

@media (max-width: 575px) {
  //для проблемы с шагом капчи для iphone 11 в хроме в рамках задачи Dev-1877
  /* selector for overflow div */
  div[style*='box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 3px;'] {
    left: 0 !important;
  }
}
</style>
