<template>
  <div></div>
</template>

<script>
import axios from "axios";
import {getAppToken} from "@/utils";

export default {
  methods: {
    async getSocialData() {
      // eslint-disable-next-line no-undef
      const captcha = await grecaptcha.execute(process.env.RECAPTCHA_ID, {action: 'login'})
      const session_secret_key = this.getSessionSecretKey()
      const access_token = this.getAccessToken()

      this.$emit('is-loading', true)

      const payload = {
        captcha,
        auth_data: {
          oauth_token: access_token,
          session_secret_key
        },
        redirect_uri: localStorage.getItem('redirect_uri')
      }

      const appToken = getAppToken()
      if  (appToken) {
        payload.mobile_app = { token : appToken}
      }

      axios.post(`/api/v1/user/odnoklassniki/socialaccount/login/`, payload).then(response => response.data)
          .then(async result => {
            const redirect_uri = localStorage.getItem('redirect_uri')

            if (redirect_uri) {
              window.location.href = `${redirect_uri}?token=${result.token}&ussid=${result.ussid}&social=odnoklassniki`
            }
          })
          .catch(e => {
            const redirect_uri = localStorage.getItem('redirect_uri')

            if (redirect_uri) {
              if (e?.response?.status === 401) {
                window.location.href = `${redirect_uri}?temp_token=${e.response.data.token}&social=odnoklassniki`
              } else {
                window.location.href = `${redirect_uri}?error=true`
              }
            }
          })
    },

    getAccessToken() {
      return /access_token=([^&]+)/.exec(this.$route.hash)?.[1] || ''
    },

    getSessionSecretKey() {
      return /session_secret_key=([^&]+)/.exec(this.$route.hash)?.[1] || ''
    },

    getError() {
      return /error=([^&]+)/.exec(this.$route.hash)?.[1] || ''
    },

    initReCaptcha: function () {
      setTimeout(function () {
        // eslint-disable-next-line no-undef
        if (typeof grecaptcha === 'undefined' || typeof grecaptcha.render ==='undefined') {
          this.initReCaptcha()
        } else {
          this.getSocialData()
        }
      }.bind(this), 100);
    }
  },

  async mounted() {
    const redirect_uri = localStorage.getItem('redirect_uri')

    if (!this.getAccessToken() || !this.getSessionSecretKey() || this.getError()) {
      window.location.href = `${redirect_uri}?error=true`
    }

    await this.$loadScript(`https://www.google.com/recaptcha/api.js?render=${process.env.RECAPTCHA_ID}`)
    this.initReCaptcha()
  }
}
</script>