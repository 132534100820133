import Vue from 'vue'
import VueRouter from 'vue-router'
import YandexAuth from '/src/social/YandexAuth'
import YandexAfterRedirect from '/src/social/YandexAfterRedirect'
import OkAuth from '/src/social/OkAuth'
import OkAfterRedirect from '/src/social/OkAfterRedirect'
import GoogleAuth from '/src/social/GoogleAuth'
import GoogleAfterRedirect from '/src/social/GoogleAfterRedirect'
import VkAuth from '/src/social/VkAuth'
import VkAfterRedirect from '/src/social/VkAfterRedirect'
import FacebookAuth from '/src/social/FacebookAuth'
import FacebookAfterRedirect from '/src/social/FacebookAfterRedirect'
import FacebookDeleteUser from '/src/social/FacebookDeleteUser'
import TelegramAuth from '/src/social/TelegramAuth'
import IndexPage from '/src/IndexPage'
import SocialRules from '/src/social/SocialRules'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: IndexPage,
  },
  {
    path: '/auth-social/yandex/',
    component: YandexAuth,
  },
  {
    path: '/auth-social/yandex-after-redirect/',
    component: YandexAfterRedirect,
  },
  {
    path: '/auth-social/ok/',
    component: OkAuth,
  },
  {
    path: '/auth-social/ok-after-redirect/',
    component: OkAfterRedirect,
  },
  {
    path: '/auth-social/google/',
    component: GoogleAuth,
  },
  {
    path: '/auth-social/google-after-redirect/',
    component: GoogleAfterRedirect,
  },
  {
    path: '/auth-social/vk/',
    component: VkAuth,
  },
  {
    path: '/auth-social/vk-after-redirect/',
    component: VkAfterRedirect,
  },
  {
    path: '/auth-social/facebook/',
    component: FacebookAuth,
  },
  {
    path: '/auth-social/facebook-after-redirect/',
    component: FacebookAfterRedirect,
  },
  {
    path: '/auth-social/facebook-delete-user/',
    component: FacebookDeleteUser,
  },
  {
    path: '/auth-social/telegram/',
    component: TelegramAuth,
  },
  {
    path: '/social-rules/',
    component: SocialRules,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
