<template>
  <div class="q-loading" v-if="show">
    <div class="q-loading__backdrop"/>
    <div class="q-loading__box">
      <svg class="q-spinner" width="80" height="80" viewBox="25 25 50 50">
        <circle class="path" cx="50" cy="50" r="20" fill="none" stroke="currentColor" stroke-width="5" stroke-miterlimit="10"/>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
.q-loading {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9998!important;
  border-radius: 0!important;
  max-width: 100vw;
  max-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  color: #000;
  position: fixed!important;
  align-items: center;
  justify-content: center;

  &__backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: .5;
    z-index: -1;
    background-color: #000;
    transition: background-color .28s;
  }

  &__box {
    align-items: center;
    flex-direction: column;
    display: flex;
    flex-wrap: wrap;
    border-radius: 4px;
    padding: 18px;
    color: #fff;
    max-width: 450px;
  }

  .path {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0;
    animation: q-mat-dash 1.5s ease-in-out infinite;
    animation-duration: 1.5s;
    animation-timing-function: ease-in-out;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-fill-mode: none;
    animation-play-state: running;
    animation-name: q-mat-dash;
  }
}

.q-spinner {
  animation: q-spin 2s linear infinite;
  transform-origin: center center;
  vertical-align: middle;
}

@keyframes q-mat-dash {
  0% {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -124px;
  }
}
</style>