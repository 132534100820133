<template>
  <div></div>
</template>

<script>

import axios from "axios";
import {getAppToken} from "@/utils";

export default {
  methods: {
    async getSocialData() {
      // eslint-disable-next-line no-undef
      const captcha = await grecaptcha.execute(process.env.RECAPTCHA_ID, {action: 'login'})
      const access_token = this.getAccessToken()

      this.$emit('is-loading', true)

      const data = await axios.get(`https://www.googleapis.com/oauth2/v1/userinfo`, {
        params: {
          access_token
        }
      }).then(({data}) => {
        return data
      })

      const payload = {
        captcha,
        social_account_data: data,
        redirect_uri: localStorage.getItem('redirect_uri')
      }

      const appToken = getAppToken()
      if  (appToken) {
        payload.mobile_app = { token : appToken}
      }

      axios.post(`/api/v1/user/google/socialaccount/login/`, payload).then(response => response.data)
          .then(result => {
            const redirect_uri = localStorage.getItem('redirect_uri')

            if (redirect_uri) {
              window.location.href = `${redirect_uri}?token=${result.token}&ussid=${result.ussid}&social=google`
            }
          })
          .catch(e => {
            const redirect_uri = localStorage.getItem('redirect_uri')

            if (redirect_uri) {
              if (e?.response?.status === 401) {
                window.location.href = `${redirect_uri}?temp_token=${e.response.data.token}&social=google`
              } else {
                window.location.href = `${redirect_uri}?error=true`
              }
            }
          })
    },

    getAccessToken() {
      return /access_token=([^&]+)/.exec(this.$route.hash)?.[1] || ''
    },

    initReCaptcha: function () {
      setTimeout(function () {
        // eslint-disable-next-line no-undef
        if (typeof grecaptcha === 'undefined' || typeof grecaptcha.render ==='undefined') {
          this.initReCaptcha()
        } else {
          this.getSocialData()
        }
      }.bind(this), 100);
    }
  },

  async mounted() {
    const redirect_uri = localStorage.getItem('redirect_uri')

    if (!this.getAccessToken) {
      window.location.href = `${redirect_uri}?error=true`
    }

    await this.$loadScript(`https://www.google.com/recaptcha/api.js?render=${process.env.RECAPTCHA_ID}`)
    this.initReCaptcha()
  }
}
</script>