<template>
  <div></div>
</template>

<script>
import axios from "axios";
import {getAppToken} from "@/utils";

export default {
  methods: {
    async getSocialData() {
      // eslint-disable-next-line no-undef
      const captcha = await grecaptcha.execute(process.env.RECAPTCHA_ID, {action: 'login'})

      this.$emit('is-loading', true)

      const token = this.getAccessToken()

      const payload = {
        captcha,
        auth_data: {
          oauth_token: token
        },
        redirect_uri: localStorage.getItem('redirect_uri')
      }

      const appToken = getAppToken()
      if  (appToken) {
        payload.mobile_app = { token : appToken}
      }

      axios.post(`/api/v1/user/yandex/socialaccount/login/`, payload).then(response => response.data)
        .then(async result => {
          const redirect_uri = localStorage.getItem('redirect_uri')

          if (redirect_uri) {
            window.location.href = `${redirect_uri}?token=${result.token}&ussid=${result.ussid}&social=yandex`
          }
        })
        .catch(e => {
          const redirect_uri = localStorage.getItem('redirect_uri')

          if (redirect_uri) {
            if (e?.response?.status === 401) {
              window.location.href = `${redirect_uri}?temp_token=${e.response.data.token}&social=yandex`
            } else {
              window.location.href = `${redirect_uri}?error=true`
            }
          }
        })
    },

    getAccessToken() {
      return /access_token=([^&]+)/.exec(this.$route.hash)?.[1] || ''
    },

    initReCaptcha: function () {
      setTimeout(function () {
        // eslint-disable-next-line no-undef
        if (typeof grecaptcha === 'undefined' || typeof grecaptcha.render ==='undefined') {
          this.initReCaptcha()
        } else {
          this.getSocialData()
        }
      }.bind(this), 100);
    }
  },

  async mounted() {
    const redirect_uri = localStorage.getItem('redirect_uri')

    if (this.getAccessToken() === '') {
      window.location.href = `${redirect_uri}?error=true`
    }

    await this.$loadScript(`https://www.google.com/recaptcha/api.js?render=${process.env.RECAPTCHA_ID}`)
    this.initReCaptcha()
  }
}
</script>