const LoadScript = {
  install: function (Vue) {
    Vue.loadScript = Vue.prototype.$loadScript = function (src, attrObject, beforeBody) { // eslint-disable-line no-param-reassign
      return new Promise(function (resolve, reject) {
        if (document.querySelector('script[src="' + src + '"]')) {
          resolve()

          return
        }

        const el = document.createElement('script')

        el.type = 'text/javascript'
        el.async = true
        el.src = src
        if (Object.prototype.toString.call(attrObject) === '[object Object]') {
          Object.keys(attrObject).forEach(key => {
            el.setAttribute(key, attrObject[key])
          })
        }

        el.addEventListener('load', resolve)
        el.addEventListener('error', reject)
        el.addEventListener('abort', reject)
        if (beforeBody) {
          document.body.appendChild(el)
        } else {
          document.head.appendChild(el)
        }
      })
    }

    Vue.unloadScript = Vue.prototype.$unloadScript = function (src, beforeBody) { // eslint-disable-line no-param-reassign
      return new Promise(function (resolve, reject) {
        const el = document.querySelector('script[src="' + src + '"]')

        if (!el) {
          reject()

          return
        }
        if (beforeBody) {
          document.body.removeChild(el)
        } else {
          document.head.removeChild(el)
        }

        resolve()
      })
    }
  },
}

export default LoadScript