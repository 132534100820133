<template>
  <div>
    <q-loader :show="isLoading" />
    <router-view @is-loading="isLoading = $event"/>
  </div>
</template>

<script>
import qLoader from "/src/components/qLoader"

export default {
  components: {
    qLoader
  },

  data() {
    return {
      isLoading: false
    }
  },

  mounted() {
    const appToken = this.$route.query.a_ssid
    if  (appToken) {
      sessionStorage.setItem('a_ssid', appToken)
    }
  }
}
</script>