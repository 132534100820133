<template>
  <h4>
    By visiting and/or using any section of the ogamesite.com website (hereinafter referred to as the “Website”), as well as by creating an account at this Website you agree to be bound by: the rules and regulations of the Website, its privacy policy, the rules of each game, all the terms of placement and distribution of advertising at the Website, special offers and bonuses running at the Website at any time. The list of the above-mentioned rules and regulations is hereinafter referred to as the “Terms of Use”. Please read the Terms of Use carefully before accepting them. If you do not agree with these terms do not create an account, and do not use and/or continue to use the Website. Your access to and use of the Website is subject to your acceptance and full compliance with these terms. These Terms of Use come into effect on 5 of July 2021.
  </h4>
</template>

<script>
export default {

}
</script>

<style scoped>
h4 {
  color: white;
  margin: 40px;
  display: block;
}
</style>