<template>
  <button class="btn tgme_widget_login_button" @click="getSocialData">
    Login <span dir="auto">facebook</span>
  </button>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import {getAppToken} from "@/utils";

export default {
  methods: {
    async getSocialData() {
      const captcha = await grecaptcha.execute(process.env.RECAPTCHA_ID, {action: 'login'})
      const token = this.getAccessToken()

      const payload = {
        captcha,
        auth_data: {
          oauth_token: token
        },
        redirect_uri: localStorage.getItem('redirect_uri')
      }

      const appToken = getAppToken()
      if  (appToken) {
        payload.mobile_app = { token : appToken}
      }

      axios.post(`/api/v1/user/facebook/socialaccount/login/`, payload).then(response => response.data)
          .then(async result => {
            const redirect_uri = localStorage.getItem('redirect_uri')

            if (redirect_uri) {
              window.location.href = `${redirect_uri}?token=${result.token}&ussid=${result.ussid}&social=facebook`
            }
          })
          .catch(e => {
            const redirect_uri = localStorage.getItem('redirect_uri')

            if (redirect_uri) {
              if (e?.response?.status === 401) {
                window.location.href = `${redirect_uri}?temp_token=${e.response.data.token}&social=facebook`
              } else {
                window.location.href = `${redirect_uri}?error=true`
              }
            }
          })
    },

    getAccessToken() {
      return /access_token=([^&]+)/.exec(this.$route.hash)?.[1] || ''
    },

    initReCaptcha: function () {
      setTimeout(function () {
        // eslint-disable-next-line no-undef
        if (typeof grecaptcha === 'undefined' || typeof grecaptcha.render ==='undefined') {
          this.initReCaptcha()
        } else {
          this.getSocialData()
        }
      }.bind(this), 100);
    }
  },

  async mounted() {
    await this.$loadScript(`https://www.google.com/recaptcha/api.js?render=${process.env.RECAPTCHA_ID}`)
    this.initReCaptcha()
  }
}
</script>

<style lang="scss" scoped>
button.tgme_widget_login_button {
  display: inline-block;
  vertical-align: top;
  font-weight: 500;
  line-height: 20px;
  background-color: #54a9eb;
  text-overflow: ellipsis;
  overflow: hidden;
  border: none;
  color: #fff;
  cursor: pointer;
  margin-left: 20px;
  margin-top: 20px;
  font-size: 16px;
  padding: 9px 21px 11px;
  border-radius: 20px;
}
</style>