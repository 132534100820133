<template>
 <div></div>
</template>

<script>
export default {
  link: `https://oauth.vk.com/authorize?client_id=8085437&display=page&redirect_uri=${location.origin}/auth-social/vk-after-redirect&response_type=code&openapi=1`,

  async mounted() {
    this.$emit('is-loading', true)
    localStorage.setItem('redirect_uri', this.$route.query.redirect_uri)
    location.href = this.$options.link
  }
}
</script>